// @flow

import React from 'react';
import { ThemeConsumer } from 'styled-components';

type Props = {
  color: string
};

function LinkArrow(props: Props): React$Node {
  return (
    <ThemeConsumer>
      {theme => (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
            fill={theme[props.color]}
          />
        </svg>
      )}
    </ThemeConsumer>
  );
}

LinkArrow.defaultProps = {
  color: 'darkText'
};

export default React.memo(LinkArrow);